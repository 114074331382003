import { useDialog } from 'primevue/usedialog'
import OrganizationContactUsModal from '~/components/Modals/OrganizationContactUsModal.vue'

export function useOrganizationContactUsModal(): (
    title: string,
    formMarkup: string,
    orgLevel: boolean,
    facilityId?: string
) => Promise<void> {
    const dialog = useDialog()

    return (
        title: string,
        formMarkup: string,
        orgLevel: boolean,
        facilityId?: string
    ) => {
        return new Promise(resolve => {
            dialog.open(OrganizationContactUsModal, {
                props: {
                    header: title,
                    pt: {
                        root: {
                            class: 'w-full h-max-md:h-full max-h-full !rounded-none md:!w-[40rem]'
                        },
                        header: {
                            class: '!rounded-none text-xl font-semibold'
                        },
                        content: {
                            class: '!rounded-none flex-1 '
                        }
                    },
                    ptOptions: {
                        mergeProps: true
                    },
                    modal: true
                },
                data: {
                    formMarkup: formMarkup,
                    orgLevel,
                    facilityId: facilityId
                },
                onClose: () => {
                    resolve()
                }
            })
        })
    }
}
