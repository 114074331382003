<template>
    <ticket-confirmation
        v-if="ticketData !== undefined"
        :ticket="ticketData"
        :show-navigation-buttons="false"
    />

    <template v-else>
        <skeleton v-if="formMarkup === undefined" />
        <form
            v-else
            @submit="onSubmit"
        >
            <content-reset :body="formMarkup" />

            <div class="mt-6">
                <label class="font-semibold">Customer</label>
                <span class="w-full">
                    <input-text
                        :model-value="viewer?.name"
                        disabled
                        class="w-full"
                        placeholder="Subject"
                        maxlength="255"
                    />
                </span>
            </div>

            <div class="mt-6">
                <label class="font-semibold">Subject</label>
                <span class="w-full">
                    <input-text
                        id="subject"
                        v-bind="subjectValue"
                        class="w-full"
                        placeholder="Subject"
                        maxlength="255"
                        autofocus
                    />
                </span>
                <span
                    id="email-error"
                    class="text-small text-red-600"
                >
                    {{ errors.subject || '&nbsp;' }}
                </span>
            </div>

            <div class="mt-2">
                <label class="font-semibold">Message</label>
                <span class="w-full">
                    <prime-textarea
                        id="message"
                        v-bind="messageValue"
                        class="w-full"
                        placeholder="Message"
                        :rows="5"
                        :cols="30"
                        maxlength="1000"
                    />
                </span>
                <span
                    id="message-error"
                    class="text-small text-red-600"
                >
                    {{ errors.message || '&nbsp;' }}
                </span>
            </div>

            <div class="flex justify-end pt-4">
                <prime-button
                    type="submit"
                    label="Submit"
                    severity="secondary"
                    :loading="isPending"
                />
            </div>
        </form>
    </template>
</template>

<script setup lang="ts">
import { inject, onMounted, ref } from 'vue'
import PrimeTextarea from 'primevue/textarea/Textarea.vue'
import InputText from 'primevue/inputtext'
import PrimeButton from 'primevue/button'
import { useCreateTicketMutation } from '~/components/Tickets/use-create-ticket-mutation'
import { useToast } from 'primevue/usetoast'
import TicketConfirmation from '~/components/Tickets/TicketConfirmation.vue'
import { useViewer } from '~/composables/use-login'
import { toTypedSchema } from '@vee-validate/yup'
import { object, string } from 'yup'
import { useForm } from 'vee-validate'
import ContentReset from '~/components/ContentReset.vue'
import Skeleton from 'primevue/skeleton'

const dialogRef = inject('dialogRef')
const formMarkup = ref()
const viewer = useViewer()

onMounted(() => {
    formMarkup.value = dialogRef.value.data.formMarkup
})

const schema = toTypedSchema(
    object({
        subject: string().max(255).required('Please enter a subject'),
        message: string().max(1000).required('Please enter a message')
    })
)

const { errors, defineComponentBinds, handleSubmit } = useForm({
    validationSchema: schema
})

const subjectValue = defineComponentBinds('subject', {
    validateOnBlur: false
})

const messageValue = defineComponentBinds('message', {
    validateOnBlur: false
})

const ticketData = ref()
const toast = useToast()
const onSubmit = handleSubmit(values => {
    mutate(
        {
            subject: values.subject,
            message: values.message,
            facilityId: dialogRef.value.data.facilityId
        },
        {
            onSuccess: data => {
                ticketData.value = data.record
                toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Ticket Created',
                    life: 3000,
                    closable: false
                })
            }
        }
    )
})

const { mutate, isPending } = useCreateTicketMutation()
</script>
