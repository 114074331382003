import { useDialog } from 'primevue/usedialog'
import AnonymousOrganizationContactUsModal from '~/components/Modals/AnonymousOrganizationContactUsModal.vue'

export function useAnonymousOrganizationContactUsModal(): (
    title: string,
    formMarkup: string
) => Promise<void> {
    const dialog = useDialog()

    return (title: string, formMarkup: string) => {
        return new Promise(resolve => {
            dialog.open(AnonymousOrganizationContactUsModal, {
                props: {
                    header: title,
                    pt: {
                        root: {
                            class: 'w-full h-max-md:h-full max-h-full !rounded-none md:!w-[40rem]'
                        },
                        header: {
                            class: '!rounded-none text-xl font-semibold'
                        },
                        content: {
                            class: '!rounded-none flex-1 '
                        }
                    },
                    ptOptions: {
                        mergeProps: true
                    },
                    modal: true
                },
                data: {
                    formMarkup: formMarkup
                },
                onClose: () => {
                    resolve()
                }
            })
        })
    }
}
