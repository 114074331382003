<template>
    <ticket-confirmation
        v-if="ticketData !== undefined"
        :ticket="ticketData"
        :show-navigation-buttons="false"
    />

    <form
        v-else
        @submit="onSubmit"
    >
        <div v-html="formMarkup"></div>

        <div class="mt-6 grid gap-4 sm:grid-cols-2">
            <div>
                <label class="font-semibold">First Name</label>
                <span class="w-full">
                    <input-text
                        id="first-name"
                        v-bind="firstNameValue"
                        class="w-full"
                        placeholder="First Name"
                        maxlength="255"
                        aria-describedby="first-name-error"
                        autofocus
                    />
                </span>
                <span
                    id="first-name-error"
                    class="text-small text-red-600"
                >
                    {{ errors.firstName || '&nbsp;' }}
                </span>
            </div>

            <div>
                <label class="font-semibold">Last Name</label>
                <span class="w-full">
                    <input-text
                        id="last-name"
                        v-bind="lastNameValue"
                        class="w-full"
                        placeholder="Last Name"
                        maxlength="255"
                        aria-describedby="last-name-error"
                    />
                </span>
                <span
                    id="last-name-error"
                    class="text-small text-red-600"
                >
                    {{ errors.lastName || '&nbsp;' }}
                </span>
            </div>
        </div>

        <div>
            <label class="font-semibold">Email Address</label>
            <span class="p-input-icon-left w-full">
                <i class="pi pi-envelope"></i>
                <input-text
                    id="email"
                    v-bind="emailValue"
                    inputmode="email"
                    type="email"
                    class="w-full !text-[16px]"
                    :class="{ 'p-invalid': errors.email }"
                    placeholder="Email Address"
                    aria-describedby="email-error"
                />
            </span>
            <span
                id="email-error"
                class="text-small text-red-600"
                >{{ errors.email || '&nbsp;' }}</span
            >
        </div>

        <div class="mt-1">
            <label class="font-semibold">Subject</label>
            <span class="w-full">
                <input-text
                    id="subject"
                    v-bind="subjectValue"
                    class="w-full"
                    placeholder="Subject"
                    maxlength="255"
                    aria-describedby="subject-error"
                />
            </span>
            <span
                id="subject-error"
                class="text-small text-red-600"
            >
                {{ errors.subject || '&nbsp;' }}
            </span>
        </div>

        <div class="mt-1">
            <label class="font-semibold">Message</label>
            <span class="w-full">
                <prime-textarea
                    id="message"
                    v-bind="messageValue"
                    class="w-full"
                    placeholder="Message"
                    :rows="5"
                    :cols="30"
                    maxlength="1000"
                    aria-describedby="message-error"
                />
            </span>
            <span
                id="message-error"
                class="text-small text-red-600"
            >
                {{ errors.message || '&nbsp;' }}
            </span>
        </div>

        <div class="mt-2">
            <rp-recaptcha @token="handleRecaptchaToken" />
            <span class="text-small text-red-600">
                {{ errors.recaptchaToken || '&nbsp;' }}
            </span>
        </div>

        <div class="mt-2 flex justify-end">
            <prime-button
                type="submit"
                label="Submit"
                severity="secondary"
                :loading="isPending"
            />
        </div>
    </form>
</template>

<script setup lang="ts">
import { inject, onMounted, ref } from 'vue'
import PrimeTextarea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import PrimeButton from 'primevue/button'
import { useToast } from 'primevue/usetoast'
import TicketConfirmation from '~/components/Tickets/TicketConfirmation.vue'
import { useCreateAnonymousTicketMutation } from '~/components/Tickets/use-create-anonymous-ticket-mutation'
import { toTypedSchema } from '@vee-validate/yup'
import { object, string } from 'yup'
import { useForm } from 'vee-validate'
import RpRecaptcha from '~/components/Verification/RpRecaptcha.vue'

const dialogRef = inject('dialogRef')
const formMarkup = ref()

onMounted(() => {
    formMarkup.value = dialogRef.value.data.formMarkup
})

const schema = toTypedSchema(
    object({
        email: string()
            .email('Please enter a valid email')
            .required('Please enter an email'),
        firstName: string()
            .max(255)
            .min(2, '2 or more characters required')
            .required('2 or more characters required'),
        lastName: string()
            .max(255)
            .min(2, '2 or more characters required')
            .required('2 or more characters required'),
        subject: string().max(255).required('Please enter a subject'),
        message: string().max(1000).required('Please enter a message'),
        recaptchaToken: string().required('Please complete the captcha')
    })
)

const { errors, defineComponentBinds, handleSubmit } = useForm({
    validationSchema: schema
})

const emailValue = defineComponentBinds('email', {
    validateOnModelUpdate: false,
    validateOnBlur: true
})

const firstNameValue = defineComponentBinds('firstName', {
    validateOnModelUpdate: false,
    validateOnBlur: true
})

const lastNameValue = defineComponentBinds('lastName', {
    validateOnModelUpdate: false,
    validateOnBlur: true
})

const subjectValue = defineComponentBinds('subject', {
    validateOnBlur: false
})

const messageValue = defineComponentBinds('message', {
    validateOnBlur: false
})

const recaptchaValue = defineComponentBinds('recaptchaToken', {
    validateOnBlur: false
})

const ticketData = ref()
const toast = useToast()
const onSubmit = handleSubmit(values => {
    mutate(
        {
            ...values
        },
        {
            onSuccess: data => {
                ticketData.value = data.record
                toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Ticket Created',
                    life: 3000,
                    closable: false
                })
            }
        }
    )
})

const { mutate, isPending } = useCreateAnonymousTicketMutation()

function handleRecaptchaToken(token: string) {
    recaptchaValue.value['onUpdate:modelValue'](token)
    recaptchaValue.value.onBlur()
}
</script>
