import { useRpQuery } from '~/composables/graphql'
import { useMutation } from '@tanstack/vue-query'
import type { CreateAnonymousTicketInput } from '~/resources/graphql/graphql'
import { graphql } from '~/resources/graphql'

export function useCreateAnonymousTicketMutation() {
    const query = useRpQuery()

    return useMutation({
        mutationFn: async (mutationData: CreateAnonymousTicketInput) => {
            const response = await query(
                graphql(/** @lang GraphQL */ `
                    mutation CreateAnonymousTicketMutation(
                        $input: CreateAnonymousTicketInput!
                    ) {
                        createAnonymousTicket(input: $input) {
                            __typename
                            ... on CreateTicketResult {
                                recordId
                                record {
                                    ...TicketConfirmationTicketFragment
                                }
                            }
                            ... on ValidationError {
                                fieldErrors {
                                    message
                                }
                            }
                        }
                    }
                `),
                {
                    input: mutationData
                }
            )

            if (
                response.createAnonymousTicket.__typename ===
                'CreateTicketResult'
            ) {
                return response.createAnonymousTicket
            } else {
                throw new Error()
            }
        }
    })
}
