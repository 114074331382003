<template>
    <slot
        :loading="waitingToShowContactUsModal"
        :handle-click="handleContactUs"
    >
        <prime-button
            icon="pi pi-question-circle"
            text
            :label="linkText"
            severity="secondary"
            :loading="waitingToShowContactUsModal"
            class="hidden md:block"
            :pt-options="{ mergeProps: true }"
            @click="handleContactUs"
        />
    </slot>
</template>

<script setup lang="ts">
import PrimeButton from 'primevue/button'
import { useOrganizationContactUsModal } from '~/components/Modals/use-organization-contact-us-modal'
import { useQuery } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import { useRpQuery } from '~/composables/graphql'
import { ref, watch } from 'vue'
import { useViewer } from '~/composables/use-login'
import { useAnonymousOrganizationContactUsModal } from '~/components/Modals/use-anonymous-organization-contact-us-modal'

const props = defineProps<{
    linkText: string
    orgLevel?: boolean
    facilityId?: string
}>()

const viewer = useViewer()
const showContactUsLoggedInModal = useOrganizationContactUsModal()
const showContactUsLoggedOutModal = useAnonymousOrganizationContactUsModal()
const waitingToShowContactUsModal = ref(false)
function handleContactUs() {
    if (data.value === undefined) {
        waitingToShowContactUsModal.value = true
    } else {
        showContactUsForm(data.value.organization.contactUsFormMarkup)
    }
}

const query = useRpQuery({ orgLevel: props.orgLevel ?? false })
const { data } = useQuery({
    queryKey: ['OrgContactUsQuery'],
    placeholderData: previousData => previousData,
    queryFn: () =>
        query(
            graphql(/* GraphQL */ `
                query OrgContactUsQuery {
                    organization {
                        contactUsFormMarkup
                    }
                }
            `)
        )
})

function showContactUsForm(markup: string) {
    if (viewer.value === undefined) {
        showContactUsLoggedOutModal(props.linkText, markup)
    } else {
        showContactUsLoggedInModal(
            props.linkText,
            markup,
            props.orgLevel === true,
            props.facilityId
        )
    }
}

watch(data, (newValue, oldValue) => {
    if (
        newValue !== undefined &&
        oldValue === undefined &&
        waitingToShowContactUsModal.value
    ) {
        showContactUsForm(newValue.organization.contactUsFormMarkup)
        waitingToShowContactUsModal.value = false
    }
})
</script>
